import React, { FC } from 'react';

import TagType from 'utils/types/Tag';

import Tag, { TagProps } from './Tag/Tag';
import { StyledContainer, StyledItemWrapper } from './Tags.styles';

export type TagsProps = {
  items: Array<TagType>;
  onItemClick?: (id: string) => void;
  variant?: TagProps['variant'];
};

const Tags: FC<TagsProps> = ({ items, onItemClick, variant = 'grey_50' }) => (
  <StyledContainer>
    {items.map(({ id, count, text, slug }) => (
      <StyledItemWrapper key={id}>
        <Tag count={count} id={id} onClick={onItemClick ? () => onItemClick(slug) : undefined} variant={variant}>
          {text}
        </Tag>
      </StyledItemWrapper>
    ))}
  </StyledContainer>
);

export default Tags;
