import React, { FC } from 'react';
import Image from '@next/image';
import UploadedImg from 'utils/types/UploadedImg';
import fromCMS from 'utils/fromCMS';
import Article from 'utils/types/Article';
import { AuthorTile, AuthorTileProps } from 'components/atoms/AuthorTile/AuthorTile';
import Tags from 'components/atoms/Tags/Tags';
import Typography from 'components/atoms/Typography/Typography';
import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import formatDate from 'utils/formatDate';
import useMedia from 'hooks/useMedia';
import {
  StyledActionsWrapper,
  StyledAuthorsWrapper,
  StyledContainer,
  StyledDateWrapper,
  StyledImageContainer,
  StyledInfoContainer,
  StyledOverlayLink,
  StyledTagsWrapper,
  StyledTitleWrapper
} from './ArticleTeaser.styles';

export type ArticleTeaserProps = {
  date: string;
  title: string;
  authors: Array<AuthorTileProps>;
  articleUrl: string;
  image: UploadedImg;
  tags?: Article['tags'];
};

const ArticleTeaser: FC<ArticleTeaserProps> = ({ date, title, authors, articleUrl, image, tags = [] }) => {
  const { isMobile, isSmallMobile } = useMedia();

  const getWidth = (): string => {
    if (isSmallMobile) {
      return '100%';
    }
    if (isMobile) {
      return '208px';
    }

    return '166px';
  };

  return (
    <StyledContainer>
      <StyledOverlayLink href={articleUrl} />
      <StyledImageContainer>
        <Image src={fromCMS(image.file.url)} alt={image.alt} layout="fill" objectFit="cover" />
      </StyledImageContainer>
      <StyledInfoContainer>
        <StyledTagsWrapper>
          <Tags items={tags} variant="backgroundLight" />
        </StyledTagsWrapper>
        <StyledDateWrapper>
          <Typography variant="body2">{formatDate(new Date(date), 'long')}</Typography>
        </StyledDateWrapper>
        <StyledTitleWrapper>
          <Typography variant="h4">{title}</Typography>
        </StyledTitleWrapper>
        <StyledActionsWrapper>
          <StyledAuthorsWrapper>
            {authors.map(({ image, name, position, id }) => (
              <AuthorTile image={image} name={name} position={position} id={id} key={id} />
            ))}
          </StyledAuthorsWrapper>
          <LinkedButton href={articleUrl} margin="0 0 4px" width={getWidth()} height={isMobile ? '56px' : '40px'}>
            Read
          </LinkedButton>
        </StyledActionsWrapper>
      </StyledInfoContainer>
    </StyledContainer>
  );
};

export default ArticleTeaser;
