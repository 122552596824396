import React, { FC } from 'react';

import Typography from 'components/atoms/Typography/Typography';

import { StyledTagContainer, StyledContentWrapper, StyledTitle, StyledCount } from './Tag.styles';

export type TagProps = {
  id: string;
  count?: number;
  onClick?: (slug: string) => void;
  variant?: 'medium' | 'white' | 'backgroundLight' | 'grey_50';
};

const Tag: FC<TagProps> = ({ count, children, onClick, variant = 'medium' }) => (
  <StyledTagContainer onClick={onClick} variant={variant}>
    <StyledContentWrapper>
      <StyledTitle>
        <Typography variant="subtitle" color="darkerGreen">
          {children}
        </Typography>
      </StyledTitle>
      <Typography variant="subtitle">{!!count && <StyledCount>({count})</StyledCount>}</Typography>
    </StyledContentWrapper>
  </StyledTagContainer>
);

export default Tag;
