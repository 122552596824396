import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacing(-0.5)};
`;

export const StyledItemWrapper = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing(1)};
`;
