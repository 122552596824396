import { AppLink } from 'components/AppLink/AppLink';
import styled from 'styled-components';
import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;

  ${mq.large`
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    overflow: hidden;
  `}
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(14, 4, 15)};
  color: ${({ theme }) => theme.colors.white};

  ${mq.large`
    padding: ${({ theme }) => theme.spacing(4, 5)};
  `}
`;

export const StyledDateWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  color: ${({ theme }) => theme.colors.primaryBlack};
  ${mq.large`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  `};
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(9)};
  color: ${({ theme }) => theme.colors.primaryBlack};

  ${mq.large`
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  `}
`;

export const StyledActionsWrapper = styled.div`
  ${mq.large`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
`;

export const StyledAuthorsWrapper = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  color: ${({ theme }) => theme.colors.primaryBlack};

  ${mq.large`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spacing(2)};
    margin-bottom: 0;
  `};
`;

export const StyledImageContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.backgroundMedium};
    opacity: 0.5;
    z-index: 1;
  }
`;

export const StyledTagsWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  flex: 1;
  justify-self: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StyledOverlayLink = styled(AppLink)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
`;
