import React, { FC } from 'react';
import Image from '@next/image';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import Hexagon from 'components/atoms/Geometric/Hexagon/Hexagon';
import Typography from 'components/atoms/Typography/Typography';
import { AppLink } from 'components/AppLink/AppLink';
import { FuncButton } from 'components/atoms/Button/FuncButton/FuncButton';
import { Marked } from 'components/Marked/Marked';

import formatDate from 'utils/formatDate';
import UploadedImg from 'utils/types/UploadedImg';
import fromCMS from 'utils/fromCMS';

import {
  StyledContainer,
  StyledImageWrapper,
  StyledLine,
  StyledInfo,
  StyledDate,
  StyledReadInfo,
  StyledReadTime
} from './ArticleTile.styles';

export type ArticleTileProps = {
  id?: string;
  date: string;
  title: string;
  readTime: MDXRemoteSerializeResult;
  image: UploadedImg;
  href: string;
};

export const ArticleTile: FC<ArticleTileProps> = ({ date, title, readTime, image, href }) => (
  <AppLink href={href}>
    <StyledContainer>
      <Hexagon width="66px" variant="purple" top="40px" left="270px" rotate="8deg" opacity={0.05} />
      <StyledImageWrapper>
        {image.file && <Image src={fromCMS(image.file.url)} alt={image.alt} layout="fill" objectFit="cover" />}
      </StyledImageWrapper>
      <StyledLine />
      <StyledInfo>
        <StyledDate>
          <Typography color="darkerGreen" variant="h6" weight="300">
            {formatDate(new Date(date), 'short')}
          </Typography>
        </StyledDate>
        <Typography color="darkerGreen" weight="700" variant="h5" ellipsisLines={3} title={title}>
          {title}
        </Typography>
        <StyledReadInfo>
          <StyledReadTime>
            <Typography weight="300" variant="subtitle">
              <Marked>{readTime}</Marked>
            </Typography>
          </StyledReadTime>
          <FuncButton variant="tertiary-white" width="100px" height="40px">
            Read
          </FuncButton>
        </StyledReadInfo>
      </StyledInfo>
    </StyledContainer>
  </AppLink>
);
