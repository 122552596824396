import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { TagProps } from './Tag';

export const StyledTagContainer = styled.div<{
  onClick: any;
  variant: TagProps['variant'];
}>`
  text-align: center;
  background: ${({ theme }) => rgba(theme.colors.lighter, 0.2)};
  display: inline-block;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  ${({ variant }) =>
    variant === 'white' &&
    css`
      background: ${({ theme }) => theme.colors.white};
    `}

  ${({ variant }) =>
    variant === 'backgroundLight' &&
    css`
      background: ${({ theme }) => theme.colors.grey_25};
    `}

    ${({ variant }) =>
    variant === 'grey_50' &&
    css`
      background: ${({ theme }) => theme.colors.grey_50};
    `}
`;

export const StyledContentWrapper = styled.div`
  padding: 6px ${({ theme }) => theme.spacing(2)};
`;

export const StyledTitle = styled.span`
  opacity: 0.7;
  display: inline-block;
`;

export const StyledCount = styled(StyledTitle)`
  opacity: 0.8;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.colors.green_100};
`;
