import React, { FC } from 'react';

import fromCMS from 'utils/fromCMS';
import UploadedImg from 'utils/types/UploadedImg';

import Typography from 'components/atoms/Typography/Typography';

import { StyledAuthorContainer, StyledContainer, StyledImage } from './AuthorTile.styles';

export type AuthorTileProps = {
  id?: string;
  image: UploadedImg;
  name: string;
  position: string;
};

export const AuthorTile: FC<AuthorTileProps> = ({ image, name, position }) => (
  <StyledContainer>
    <StyledImage
      src={fromCMS(image.file.url)}
      alt={image.alt}
      width={48}
      height={48}
      objectFit="cover"
      hasFade={false}
    />
    <StyledAuthorContainer>
      <Typography variant="body2" weight="600">
        {name}
      </Typography>
      <Typography variant="label">{position}</Typography>
    </StyledAuthorContainer>
  </StyledContainer>
);
